import _reactDomDevelopment from "./cjs/react-dom.development.js";
function checkDCE() {
  /* global __REACT_DEVTOOLS_GLOBAL_HOOK__ */
  if (typeof __REACT_DEVTOOLS_GLOBAL_HOOK__ === "undefined" || typeof __REACT_DEVTOOLS_GLOBAL_HOOK__.checkDCE !== "function") {
    return;
  }
  if (true) {
    // This branch is unreachable because this function is only called
    // in production, but the condition is true only in development.
    // Therefore if the branch is still here, dead code elimination wasn't
    // properly applied.
    // Don't change the message. React DevTools relies on it. Also make sure
    // this message doesn't occur elsewhere in this function, or it will cause
    // a false positive.
    throw new Error("^_^");
  }
  try {
    // Verify that the code above has been dead code eliminated (DCE'd).
    __REACT_DEVTOOLS_GLOBAL_HOOK__.checkDCE(checkDCE);
  } catch (err) {
    // DevTools shouldn't crash React, no matter what.
    // We should still report in case we break this code.
    console.error(err);
  }
}
export { _reactDomDevelopment as default };
export const __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED = _reactDomDevelopment.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
  createPortal = _reactDomDevelopment.createPortal,
  createRoot = _reactDomDevelopment.createRoot,
  findDOMNode = _reactDomDevelopment.findDOMNode,
  flushSync = _reactDomDevelopment.flushSync,
  hydrate = _reactDomDevelopment.hydrate,
  hydrateRoot = _reactDomDevelopment.hydrateRoot,
  render = _reactDomDevelopment.render,
  unmountComponentAtNode = _reactDomDevelopment.unmountComponentAtNode,
  unstable_batchedUpdates = _reactDomDevelopment.unstable_batchedUpdates,
  unstable_renderSubtreeIntoContainer = _reactDomDevelopment.unstable_renderSubtreeIntoContainer,
  version = _reactDomDevelopment.version;